import React from 'react';
import { graphql } from 'gatsby';
import { format } from 'date-fns';

import Layout from '../components/layout';
import Bio from '../components/bio';
import SEO from '../components/seo';
import Link from '../components/link';
import { renderAst } from '../components/myElements';

const BlogPostTemplate = ({ data, pageContext, location }) => {
    const post = data.markdownRemark;
    const siteTitle = data.site.siteMetadata.title;
    const { previous, next } = pageContext;

    return (
        <Layout location={location} title={siteTitle}>
            <Bio title="Home" />
            <SEO title={post.frontmatter.title} description={post.excerpt} />
            <article>
                <header className="pt-10 pb-8">
                    <h1 className="text-green-light text-lg">{post.frontmatter.title}</h1>
                    <p className="text-gray"> {format(new Date(post.frontmatter.date), 'yyyy-MM-dd')}</p>
                </header>
                <section>{renderAst(post.htmlAst)}</section>
                <footer>
                    <Bio title="Home" />
                </footer>
            </article>

            <nav className="mb-8">
                <ul className="flex justify-between">
                    <li>
                        {previous && (
                            <Link href={previous.fields.slug} rel="prev">
                                ← {previous.frontmatter.title}
                            </Link>
                        )}
                    </li>
                    <li>
                        {next && (
                            <Link href={next.fields.slug} rel="next">
                                {next.frontmatter.title} →
                            </Link>
                        )}
                    </li>
                </ul>
            </nav>
        </Layout>
    );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        site {
            siteMetadata {
                title
            }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            excerpt(pruneLength: 160)
            htmlAst
            frontmatter {
                title
                date
            }
        }
    }
`;
