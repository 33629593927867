import React from 'react';
import rehypeReact from 'rehype-react';

export const H1 = ({ children }) => {
    return <h1 className="text-green-light my-8">{children}</h1>;
};

export const H2 = ({ children }) => {
    return <h2 className="text-green-light my-6">{children}</h2>;
};

export const H3 = ({ children }) => {
    return <h3 className="text-green-light my-5">{children}</h3>;
};

export const Image = (props) => {
    return <img className="my-3" {...props} />;
};

export const A = ({ children, ...props }) => {
    return (
        <a {...props} className="text-green-light">
            {children}
        </a>
    );
};

export const Blockquote = ({ children }) => {
    return <blockquote className="border-l-4 border-main pl-4 my-4">{children}</blockquote>;
};

export const Code = ({ children, className }) => {
    return <code className={`${className} text-sm`}>{children}</code>;
};

export const Pre = ({ children, className }) => {
    return <pre className={`${className} text-sm`}>{children}</pre>;
};

export const P = ({ children }) => {
    return <p className="leading-7">{children}</p>;
};

export const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: {
        h1: H1,
        h2: H2,
        h3: H3,
        p: P,
        a: A,
        img: Image,
        blockquote: Blockquote,
        pre: Pre,
        code: Code,
    },
}).Compiler;
